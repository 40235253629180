@import '../../Keyframes';

.articles {
    padding: 50px var(--default-desktop-padding) 120px;
    margin: 0 auto;
    max-width: var(--default-max-width);
    display: flex;
    flex-direction: column;

    .text {
        color: var(--color-grey);
        font-size: 16px;
        font-weight: 300;
        max-width: 700px;
        margin-bottom: 40px;

        b {
            font-weight: 600;
        }
    }

    @media screen and (max-width: 750px) {
        h4 {
            margin-top: 0;
        }

        .articles-list {
            gap: 32px;

            @for $i from 5 through 8 {
                a:nth-child(#{$i}) {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        padding: 50px var(--default-mobile-padding);
    }
}

.articles-list {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 32px;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (max-width: 1330px) {
        gap: 24px;
    }

    @media screen and (max-width: 1100px) {
        gap: 16px;
    }
}

.show-more {
    margin: 48px 0 0;

    h3 {
        margin: 0 0 10px;
        font-size: 28px;
        font-weight: 400;
        font-family: Muli, sans-serif !important;
    }

    .container {
        color: var(--link-dark);
        font-size: 16px;
        font-weight: 300;
        display: flex;
        gap: 10px;
        width: unset;

        a {
            color: var(--link-dark);
            text-decoration: underline;
        }

        svg {
            color: var(--color-black);
        }
    }

    @media screen and (max-width: 750px) {
        margin: 0 auto 0 0;

        h3 {
            display: none;
        }

        svg {
            display: none;
        }

        .container {
            a {
                border-radius: 4px;
                background-color: var(--color-sky-darker);
                padding: 8px 24px;
                text-decoration: unset;
                color: var(--color-white);
            }

            a:hover {
                background-color: var(--color-sky-dark);
            }
        }
    }
}

.article {
    background: #FFF;
    border: 2px solid #F2F2F2;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 400px;
    width: calc(25% - 24px);
    text-decoration: none;
    color: var(--color-coal);

    animation: slideInFromBottom 1s ease-in-out;

    h4 {
        margin: 0;
        font-size: 18px;
        font-weight: 400 !important;
        line-height: 24px;
        min-height: 48px;
        color: var(--color-coal);
    }

    .summary {
        font-size: 16px;
        font-weight: 300;
        color: var(--color-grey);
    }

    .content {
        display: flex;
        margin: 16px 0;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-between;

        .author {
            display: flex;
            flex-direction: row;
            gap: 8px;
            align-items: flex-end;
            align-content: flex-start;
            margin: 10px 16px;
            font-size: 12px;
            font-weight: 300;

            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            .name-date-wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-grow: 1;
                align-items: flex-end;
            }

            .name {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                gap: 2px;

                :first-child {
                    font-weight: 700;
                }
            }
        }
    }

    .image {
        overflow: hidden;
        font-size: 0;

        img {
            object-fit: cover;
            aspect-ratio: 3 / 2;
            width: 100%;
            height: auto;
            transition: transform 0.3s ease;
        }
    }

    .article-title {
        margin: 10px 10px 10px 16px;
        font-size: 14px;
        min-height: 60px;
    }

    .category {
        display: flex;
        gap: 10px;
        color: var(--color-metal);

        svg {
            color: var(--color-sky-darker);
        }
    }

    &:hover {
        .image img {
            transform: scale(1.05);
        }
    }

    @media screen and (max-width: 1330px) {
        width: unset;
        flex-basis: calc(33% - 16px);
    }

    @media screen and (max-width: 1100px) {
        flex-basis: calc(50% - 8px);
    }

    @media screen and (max-width: 750px) {
        flex-basis: 100%;
        min-height: unset;
        animation: fadeInLate 0.8s ease-in;
    }
}
