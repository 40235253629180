@keyframes slideInFromLeft {
    0% {
        opacity: 0;
        transform: translateX(-5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    0% {
        opacity: 0;
        transform: translateX(5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromTop {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(10%);
        opacity: 0;
    }
    50% {
        transform: translateY(10%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInLate {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideInFromLeftMobile {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
        transform: translateX(-5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInMobile {
    0% {
        opacity: 0;
        transform: translateX(-5%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes jiggle {
    30% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(20%);
    }
    100% {
        transform: translateY(0);
    }
}
