

.card {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    box-shadow: 0 0 32px 0 #0000000D;
    color: var(--color-coal);
}
